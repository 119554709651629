/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"
import { TheSeo } from "x"

const NotFoundPage = () => (
	<Fragment>
		<TheSeo title="404: Not found" />
		<h1>NOT FOUND</h1>
		<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
	</Fragment>
)

export default NotFoundPage
